import { LitElement, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { router } from './router';

@customElement('app-index')
export class AppIndex extends LitElement {

  private _logout: Function;
  private _login: Function;

  @state()
  _userName: string = '';

  constructor() {
    super();
    this._logout = () => { return; };
    this._login = () => { return; };
  }

  createRenderRoot() {
    return this;
  }




  async firstUpdated() {
    console.log('firstUpdated');
    const { auth, logoutClicked, loginClicked } = await import('./firebaseConfig');
    const { onAuthStateChanged } = await import('firebase/auth');
    this._logout = logoutClicked;
    this._login = loginClicked;

    router.addEventListener('route-changed', () => {
      if ("startViewTransition" in document) {
        return (document as any).startViewTransition(() => {
          this.requestUpdate();
        });
      }
      else {
        this.requestUpdate();
      }
    });
    const self = this;
    onAuthStateChanged(auth, (user: any) => {
      console.log('onAuthStateChanged called');
      if (user) {
        // console.log(JSON.stringify(user, null, 2));
        const n = user.displayName.split(' ');
        self._userName = `${n[0]} ${n[n.length - 1]}`;
        const el = document.getElementById("spinner");
        if (el) {
          console.log("hide the spinner");
          el.classList.add("hidden");
        }
        const logoutButton = document.getElementById('logout-button');
        logoutButton?.classList.remove('hidden');
        const loginButton = document.getElementById('login-button');
        loginButton?.classList.add('hidden');
        console.log(`from onAuthStateChanged is logged in`);
        router.navigate('/home');
      } else {
        self._userName = '';
        const logoutButton = document.getElementById('logout-button');
        logoutButton?.classList.add('hidden');
        const loginButton = document.getElementById('login-button');
        loginButton?.classList.remove('hidden');
        console.log('no user data, user is logged out');
        router.navigate('/');
      }
    });
  }

  _logoutClicked(e: Event) {
    console.log('_logoutClicked');
    e.preventDefault();
    this._logout();
  }

  _loginClicked(e: Event) {
    console.log('_loginClicked');
    e.preventDefault();
    const el = document.getElementById("spinner");
    if (el) {
      console.log("show the spinner");
      el.classList.remove("hidden");
    }
    this._login();
  }

  _goHome(e: Event) {
    e.preventDefault();
    this._login();
  }

  render() {
    return html`
<div class="flex flex-col min-h-screen">

<nav class="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
  <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2.5">
    <a href="" @click="${this._goHome}" class="flex items-center">
      <img src="" class="hidden h-8 mr-3" alt="Flowbite Logo">
      <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">ShortNotes</span>
      </a>
    <div class="flex md:order-2 items-center">
      <span id="username" class="self-center pr-4 text-base font-semibold whitespace-nowrap dark:text-white">${this._userName}</span>
      <button type="button" 
        id="login-button" 
        @click="${this._loginClicked}"
        class="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
        <svg class="w-4 h-4 mr-2" aria-hidden="true" 
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 18 19">
      <path fill-rule="evenodd"
      d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" 
      clip-rule="evenodd"/>
      </svg>
      Login com Google
      </button>
      <button type="logout"
      id="logout-button" 
      @click="${this._logoutClicked}"
        class="hidden text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <svg class="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3"/>
  </svg>
 
</button>
    </div>
  </div>
</nav>
${router.render()}
<div role="status" id="spinner" class="absolute top-1/2  left-1/2 hidden">
    <svg aria-hidden="true" class="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
  
<footer class="fixed bottom-0 left-0 z-20 w-full p-2.5 bg-white border-t border-gray-200 shadow flex tems-center justify-center dark:bg-gray-800 dark:border-gray-600">
  <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
    © 2023 CG</a>
  </span>
  <ul class="flex hidden flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
  <li>
  <a href="#" class="mr-4 hover:underline md:mr-6">About</a>
  </li>
  <li>
  <a href="#" class="mr-4 hover:underline md:mr-6">Privacy Policy</a>
  </li>
  <li>
  <a href="#" class="mr-4 hover:underline md:mr-6">Licensing</a>
  </li>
  <li>
  <a href="#" class="hover:underline">Contact</a>
  </li>
  </ul>
  </footer>
</div>
`
  }
}

