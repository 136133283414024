import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
// import { loginClicked } from '../firebaseConfig';

@customElement('app-login')
export class AppHome extends LitElement {

  constructor() {
    super();
  }

  createRenderRoot() {
    return this;
  }

  render() {
    return html`

<main id="login-screen" class="min-h-screen w-screen flex flex-col justify-center items-center mx-auto">


  <section class="bg-white dark:bg-gray-900 bg-[url('/assets/imgs/hero-pattern.svg')] dark:bg-[url('/assets/imgs/hero-pattern-dark.svg')]">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
      <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Um jeito fácil de manter suas anotações</h1>
      <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">Digite seu texto ou use o reconhecimento de voz para poder acessá-lo de qualquer outro dispositivo.</p>
    </div>
    <div class="bg-gradient-to-b from-blue-50 to-transparent dark:from-blue-900 w-full h-full absolute top-0 left-0 z-0"></div>
  </section>


</main>
`;
  }
}
